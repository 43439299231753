<template>
  <div class="wrapper w-100">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12">
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="fa fa-wrench"></i><strong> Matériel</strong>
              <info-message>
                Le matériel vous permet de travailler dans vos plantations.
                En plus du matériel proposé, vous pouvez créer vos propres matériels.
              </info-message>
              <div class="card-header-actions">
                <b-button variant="success" @click="onExport" title="Exporter en PDF" class="mr-2" v-b-tooltip.hover>
                  <i class="fa fa-upload"></i>
                </b-button>

                <b-button variant="primary" @click="newEquipmentModal = true">
                  <i class="fa fa-plus"></i>
                  Nouveau matériel
                </b-button>

                <b-modal :title="modalTitle" class="modal-primary" v-model="newEquipmentModal"
                         @hide="onModalClose" size="lg">
                  <b-form-row>
                    <b-col class="mr-4">
                      <p class="text-danger" v-if="hasError">{{errorMessage}}</p>
  
                      <c-input container-class="mb-3" label="Nom du matériel" placeholder="Ex: Machette"
                               v-model="name" :state="nameState">
                        Veuillez saisir un nom
                      </c-input>
  
                      <c-input container-class="mb-3" type="quantity-unit" :label="selectedEquipment ?'Quantité totale':'Quantité'" placeholder="Ex: 12"
                               v-model="quantity" :unit.sync="unit" :units="units"
                               :state="quantityState" :disabled="editing" :unit-disabled="editing">
                        Veuillez entrer une quantité
                        <template slot="infoMessage">
                          Choisissez l'unité de la quantité au fond à droite (→).
                        </template>
                      </c-input>
  
                      <c-input container-class="mb-3" type="price" label="Prix unitaire" placeholder="Ex: 500"
                               v-model="price" :state="priceState" :currency="exploitationCurrency">
                        Veuillez entrer un prix
                      </c-input>
  
                      <c-input container-class="mb-3 mt-3" type="select" label="Entrepôt" v-model="warehouse"
                               :options="warehouses" :state="warehouseState" :disabled="editing">
                        <template slot="first">
                          <option :value="null">-- Sélectionnez un entrepôt --</option>
                        </template>
                        Veuillez sélectionner un entrepôt
                      </c-input>
  
                      <c-input container-class="mb-3" type="select" label="Nature du matériel" v-model="nature"
                               :options="equipmentNatureList" :state="equipmentNatureState">
                        <template slot="first">
                          <option :value="null">-- Sélectionnez la nature du matériel --</option>
                        </template>
                        Veuillez sélectionner la nature du matériel
                      </c-input>

                    </b-col>
                    <b-col>
                      <c-input type="image" v-model="picture" default="DEFAULT_EQUIPMENT_PICTURE"></c-input>
                      <c-input container-class="mb-3" type="textarea" label="Commentaire"
                               placeholder="Entrez un commentaire..." v-model="comment">
                      </c-input>

                    </b-col>
                  </b-form-row>

                  <div slot="modal-footer" class="w-100 text-center">
                    <button-spinner variant="success" type="submit" class="px-4" @click="onSubmit"
                                    :fetching="fetchingNewEquipment">
                      {{submitBtnText}}
                    </button-spinner>
                    <b-button variant="secondary" @click="newEquipmentModal = false" class="ml-2">
                      Annuler
                    </b-button>
                  </div>
                </b-modal>
              </div>
            </div>
            <div class="text-center" v-if="fetchingEquipmentList">
              <b-spinner></b-spinner>
              <br>
              Chargement...
            </div>
            <b-row v-else-if="equipments.length" class="exploitations">
              <c-table :table-data="equipments" :fields="fields" :actions="actions" :per-page=5 striped
                       outlined></c-table>
            </b-row>
            <p class="text-center" v-else>Aucun matériel dans cette exploitation.</p>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {Api, Regex, Toast, asset, env, IO, price} from "../../../helpers"

  export default {
    name: "ExploitationEquipments",
    title: "PIA - Matériel",
    data() {
      return {
        equipments: [],
        fields: [
          {key: 'picture', label: 'Aperçu', asset: this.asset},
          {key: 'name', label: 'Nom', sortable: true},
          {key: 'qty', label: 'Quantité'},
          {key: 'unitPrice', label: 'Prix unitaire'},
          {key: 'nature', label: 'Nature du matériel'},
          {key: 'comment', label: 'Commentaire'},
          {key: 'actions', label: 'Actions'},
        ],
        actions: [
          {type: 'primary', icon: 'icon-note', title: 'Modifier', handler: this.editEquipment},
          {type: 'danger', icon: 'icon-trash', title: 'Supprimer', handler: this.deleteEquipment},
        ],
        newEquipmentModal: false,
        submitted: false,
        error: null,
        fetchingNewEquipment: false,
        fetchingEquipmentList: false,
        fetchingUnitList: false,
        selectedEquipment: null,
        picture: null,
        name: '',
        quantity: '',
        price: '',
        unit: null,
        nature: null,
        comment: '',
        units: [],
        warehouse: null,
        fetchingWarehouseList: false,
        warehouses: [],

        equipmentNatureList: [],
        fetchingEquipmentNatureList: false,
      }
    },
    created() {
      this.fetchingEquipmentList = true
      Api.get('/exploitation/equipment/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.equipments = res.data.data.map(item => ({
              ...item,
              qty: this.getQty(item),
              unitPrice: price(item.price),
              nature: item.equipmentNature.name
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingEquipmentList = false
        })

      this.fetchingUnitList = true
      Api.get('/unit/list')
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.units = res.data.data.map(item => ({
              value: item.slug,
              text: item.slug
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingUnitList = false
        })

      this.fetchingWarehouseList = true
      Api.get('/exploitation/warehouse/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.warehouses = res.data.data.map(item => ({
              value: item.id,
              text: item.name
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingWarehouseList = false
        })

      this.fetchingEquipmentNatureList = true
      Api.get('/exploitation/equipment/nature/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.equipmentNatureList = res.data.data.map(item => ({
              text: item.name,
              value: item.id
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingEquipmentNatureList = false
        })
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      }
    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      nameState() {
        return !this.submitted || this.name.trim().length >= 3 ? null : false
      },
      quantityState() {
        return !this.submitted || (!this.warehouse && !this.quantity) || Regex.isPositiveNumber(this.quantity)
          ? null : false
      },
      priceState() {
        return !this.submitted || Regex.isPositiveNumber(this.price)
          ? null : false
      },
      warehouseState() {
        return !this.submitted || this.selectedEquipment || (!this.warehouse && !this.quantity) || this.warehouse != null
          ? null : false
      },
      equipmentNatureState() {
        return !this.submitted || this.nature != null ? null : false
      },
      editing() {
        return !!this.selectedEquipment
      },
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      },
      submitBtnText() {
        return this.selectedEquipment ? 'Enregistrer' : 'Créer'
      },
      modalTitle() {
        return this.selectedEquipment ? 'Modifier le matériel' : 'Nouveau matériel'
      },
      exploitationCurrency() {
        return price()
      }
    },
    methods: {
      asset(path) {
        return asset(path, env('DEFAULT_EQUIPMENT_PICTURE'))
      },
      valid() {
        return this.name.trim().length >= 3
          && ((!this.warehouse && !this.quantity) || Regex.isPositiveNumber(this.quantity))
          && Regex.isPositiveNumber(this.price)
          && (this.selectedEquipment || (!this.warehouse && !this.quantity) || this.warehouse != null)
          && this.nature != null
      },
      onModalClose() {
        this.submitted = false
        this.error = null
        this.fetchingNewEquipment = false
        this.picture = null
        this.name = ''
        this.quantity = ''
        this.unit = null
        this.price = ''
        this.nature = null
        this.comment = ''
        this.selectedEquipment = null
        this.warehouse = null
      },
      getQty(item) {
        return item.quantity ? item.quantity + (item.unit ? ' ' + item.unit : '') : ''
      },
      newEquipment() {
        this.submitted = true
        if (!this.valid()) return

        this.fetchingNewEquipment = true
        Api.post('/exploitation/equipment/create', {
          exploitationId: this.exploitationId,
          name: this.name,
          quantity: this.quantity,
          unit: this.unit,
          price: this.price,
          warehouseId: this.warehouse,
          equipmentNatureId: this.nature,
          comment: this.comment,
          picture: this.picture,
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              this.newEquipmentModal = false
              const equipment = res.data.data
              this.equipments.push({
                ...equipment,
                qty: this.getQty(equipment),
                unitPrice: price(equipment.price),
                nature: equipment.equipmentNature.name,
              })

              Toast.success('Matériel créé avec succès !')
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingNewEquipment = false
          })
      },
      updateEquipment() {
        this.submitted = true
        if (!this.valid()) return

        this.fetchingNewEquipment = true
        Api.post('/exploitation/equipment/update', {
          exploitationId: this.exploitationId,
          equipmentId: this.selectedEquipment.id,
          name: this.name,
          unit: this.unit,
          price: this.price,
          equipmentNatureId: this.nature,
          comment: this.comment,
          picture: Regex.isNullOrURL(this.picture) ? null : this.picture,
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              this.newEquipmentModal = false
              this.equipments = this.equipments.filter((item, index, array) => item.id !== this.selectedEquipment.id)
              const equipment = res.data.data
              this.equipments.push({
                ...equipment,
                qty: this.getQty(equipment),
                unitPrice: price(equipment.price),
                nature: equipment.equipmentNature.name,
              })

              Toast.success('Matériel mis à jour avec succès !')
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingNewEquipment = false
          })
      },
      onSubmit() {
        this.selectedEquipment ? this.updateEquipment() : this.newEquipment()
      },
      editEquipment(equipment) {
        this.selectedEquipment = equipment
        this.picture = asset(equipment.picture)
        this.name = equipment.name
        this.quantity = equipment.quantity
        this.unit = equipment.unit
        this.price = equipment.price
        this.nature = equipment.equipmentNature.id
        this.comment = equipment.comment
        this.newEquipmentModal = true
      },
      deleteEquipment(equipment) {
        const res = confirm(`Etes-vous sûr de vouloir supprimer le matériel "${equipment.name}" ?`)
        if (res) {
          Api.post('/exploitation/equipment/delete', {
            exploitationId: this.exploitationId,
            equipmentId: equipment.id
          })
            .then(res => {
              if (res.data.status === 'success' && res.data.data) {
                this.equipments = this.equipments.filter((item, index, array) => item.id !== equipment.id)
                Toast.success('Matériel supprimé avec succès !')
              }
              else {
                this.error = res.data.error
              }
            })
            .catch(error => {
              this.error = {
                message: 'Echec de la connexion au serveur'
              }
            })
            .then(() => {
              // this.fetchingNewEquipment = false
            })
        }
      },
      onExport() {
        IO.exportDataTable(this.equipments, 'equipments', {
          title: 'Exploitation: ' + this.$store.getters.exploitationName,
          name: 'Liste du matériel',
          headers: [
            {title: 'Aperçu', key: 'picture', width: 'auto', asset: this.asset},
            {title: 'Nom', key: 'name', width: 'auto'},
            {title: 'Quantité', key: 'qty', width: 'auto'},
            {title: 'P.U', key: 'unitPrice', width: 'auto'},
            {title: 'Nature', key: 'nature', width: 'auto'},
            {title: 'Commentaire', key: 'comment', width: '*'},
          ]
        })
      }
    }
  }
</script>

<style scoped>
  #equipment-img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
  }

  .autocomplete {
    width: 100%;
    border-radius: 0;
  }

  .num-badge {
    width: 15px;
    height: 15px;
    margin-left: -3px;
    text-align: center;
    padding: auto 0;
  }
</style>
